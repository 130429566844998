import React, { Component } from 'react';
import OperationtMode from 'common/utils/OperationtMode';
import moment from 'moment';
import { DatePicker,Form, Row, Col, Input, Button,Select,Tabs } from 'antd';
import { Header, Modal,Message } from 'semantic-ui-react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  userChangeLogGridColumn,
  userAccessLogGridColumn } from './GridColumnMap';
import config from 'common/config';

const { Option } = Select;
class UserManageDialog extends Component {
  constructor(props) {
    super(props);
    const _requireRule = this._requireRule,_patternRule = this._patternRule;
    const pwdReg = new RegExp("(?=.*\\d)(?=.*[A-Za-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$");
    const pwdMsg = "password must contain English letters & symbols, numbers, and length must >= 8";
    this.state = {
      columnDefs: userChangeLogGridColumn,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 50,
        width: 130,
        sortable: true,
        filter: true,
        resizable: true
      },
      isActiveList: [{'name': 'Yes','value': 1},{'name': 'No','value': 0}],
      roleList: [
        {'name': 'Admin','value': 'Admin'},
        {'name': 'APM','value': 'APM'},
        {'name': 'BD','value': 'BD'},
        {'name': 'Client Service','value': 'Client Service'},
        {'name': 'Compliance','value': 'Compliance'},
        {'name': 'IT','value': 'IT'},
        {'name': 'Management','value': 'Management'},
        {'name': 'Operation','value': 'Operation'},
        {'name': 'PM','value': 'PM'},
        {'name': 'Research','value': 'Research'},
        {'name': 'Risk','value': 'Risk'},
        {'name': 'Trader','value': 'Trader'},
        {'name': 'Marketing','value': 'Marketing'},
        {'name': 'Trading','value': 'Trading'},
      ],
      deskList: [
        {'name': 'BO','value': 'BO'},
        {'name': 'FO','value': 'FO'},
        {'name': 'MO','value': 'MO'},
      ],
      officeLocationList:[
        {'name': 'Shanghai','value': 'Shanghai'},
        {'name': 'ShenZhen','value': 'ShenZhen'},
        {'name': 'SanYa','value': 'SanYa'},
        {'name': 'SanYa(QDLP)','value': 'SanYa(QDLP)'},
        {'name': 'HongKong','value': 'HongKong'},
        {'name': 'Japan','value': 'Japan'},
        {'name': 'India','value': 'India'},
        {'name': 'Singapore','value': 'Singapore'},
        {'name': 'Taipei','value': 'Taipei'},
      ],
      rules: {
        'password': [_requireRule('password'),_patternRule(pwdReg,pwdMsg)],
        'userAccountName': [_requireRule('User AccountName')],
        'englishName': [_requireRule('English Name')],
        'email': [_requireRule('Email')],
        'firm': [_requireRule('Firm')],
        'isActive': [_requireRule('Active')],
        'position': [_requireRule('Position')],
        'officeLocation': [_requireRule('OfficeLocation')]
      },
      updateReadOnlyFileds: ["userAccountName","domainName",'englishName'],
      resetCanEditFileds: ["password"]
    };
  }
  saveData = () =>{

  }

  _requireRule = (name) =>{
    return { required: true, message: `Please input ${name}!` }
  }

  _patternRule = (pattern,msg) =>{
    return {  pattern: pattern, message: msg };
  }

  _getFields = () => {
    const {
      mode
    } = this.props.userManageControlModal;
    const{rules} = this.state;
    const children = [];
    if(OperationtMode.NEW === mode){
      children.push(this._createNormalInput('domainName','Domain Name'));
    }
    //children.push(this._createNormalInput('userAccountName','User Account Name',rules['userAccountName']));
    children.push(this._createNormalInput('englishName','English Name',rules['englishName']));
    children.push(this._createNormalInput('chineseUserName','Chinese User Name'));
    if(OperationtMode.NEW === mode || OperationtMode.RESET_PWD === mode){
      children.push(this._createNormalPwd('password','Password',rules['password'],24));
    }
    //children.push(this._createNormalPwd('repeatPassword','Repeat Password'));
    children.push(this._createNormalSelect('desk','Desk', null , 8, this.state.deskList, false));
    children.push(this._createNormalSelect('position','Position', null , 8, this.state.roleList, false));
    children.push(this._createNormalInput('title','Title', null , 8));
    children.push(this._createNormalInput('firm','Firm', null , 8));
    children.push(this._createNormalInput('seniority','Seniority', null , 8));
    children.push(this._createNormalInput('directLine','Direct Line', null, 8));
    children.push(this._createNormalInput('phoneExt','Phone Ext', null, 8));
    children.push(this._createNormalInput('handPhone','Hand Phone', null, 8));
    children.push(this._createNormalInput('email','Email', rules['email'] , 24));
    children.push(this._createNormalSelect('isActive','Avtive', rules['isActive'] , 8, this.state.isActiveList, false));
    children.push(this._createNormalSelect('officeLocation','Office Location', rules['officeLocation'] , 8, this.state.officeLocationList, false));
    children.push(this._createNormalDateInput('joinDate','Join Date', null , 8));
    children.push(this._createNormalDateInput('contractEndDate','Contract End Date', null , 8));
    if(OperationtMode.UPDATE === mode){
      children.push(this._createNormalDateInput('leaveDate','Leave Date', null , 8));
    }
    // children.push(this._createNormalInput('bbgLoginName','BBG LoginName', null , 8));
    // children.push(this._createNormalInput('bbgEmsxAuthId','BBG EmsxAuthId', null , 8));
    // children.push(this._createNormalInput('beauchampLoginName','Beauchamp LoginName', null , 8));
    return children;
  }

  _createNormalInput = (name,lable,rules,span) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Input
            readOnly={this._readOnly(name)}
            onChange={e=>{
              this.onInputChangeEventHandle(e,name);
            }}
          />
        </Form.Item>
      </Col>);
  }

  _createNormalDateInput = (name,lable,rules,span) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <DatePicker
            allowClear={false}
            format="YYYY-MM-DD"
            disabled={this._readOnly(name)}
            style={{width:'100%'}}
            onChange={(date, dateString)=>{
              this.onValueChangeHandle(dateString,name);
            }}
          />
        </Form.Item>
      </Col>);
  }

  _createNormalPwd = (name,lable,rules,span) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Input.Password
            readOnly={this._readOnly(name)}
            onChange={e=>{
              this.onInputChangeEventHandle(e,name);
            }}
            />
        </Form.Item>
      </Col>);
  }

  _createNormalSelect = (name,lable,rules,span,options,allowClear) =>{
    if(!span){
      span = 12;
    }
    return (
      <Col span={span}>
        <Form.Item
          name={name}
          label={lable}
          rules={rules}
        >
          <Select
            allowClear={allowClear}
            disabled={this._readOnly(name)}
            onChange={(value) => {
              this.onValueChangeHandle(value, name);
            }}>
            {options.map(item => {
              return (<Option key={item.value} value={item.value}>{item.name}</Option>);
            })}
          </Select>
        </Form.Item>
      </Col>);
  }

  onInputChangeEventHandle = (event, fieldName) => {
    const filedValue = event.target.value;
    this.onValueChangeHandle(filedValue,fieldName)
  };

  onValueChangeHandle = (value,name) =>{
    const {
      detailData
    } = this.props.userManageControlModal;
    const updateData = {
      ...detailData,
      [name]: value
    }
    this.props.changeUserManageControlModalInput({
      name: 'detailData',
      value: updateData
    });
  }

  _readOnly = (field) =>{
    const {
      mode
    } = this.props.userManageControlModal;
    const{
      updateReadOnlyFileds,
      resetCanEditFileds
    } = this.state;
    if(mode===OperationtMode.DETAIL){
      return true;
    }if(mode===OperationtMode.UPDATE && field && updateReadOnlyFileds.indexOf(field)>=0){
      return true;
    }if(mode===OperationtMode.RESET_PWD && field  && resetCanEditFileds.indexOf(field)<0){
      return true;
    }
    return false;
  }

  _submitData = () => {
    const {
      detailData,
      mode
    } = this.props.userManageControlModal;
    const submitData = {
      ...detailData,
      op: mode,
      joinDate: moment(detailData.joinDate).format('YYYY-MM-DD'),
      leaveDate: detailData.leaveDate?moment(detailData.leaveDate).format('YYYY-MM-DD'):null,
      contractEndDate: detailData.contractEndDate?moment(detailData.contractEndDate).format('YYYY-MM-DD'):null,
      system: config.system
    }
    this.props.submitData(submitData);
  }

  submitForm = () => {
    this.formRef.current.submit();
  }

  _createForm = () => {
    const {
      detailData,
      submitErrorMsg
    } = this.props.userManageControlModal;
    this.formRef = React.createRef();
    return (
    <Form
      ref={this.formRef}
      className="ant-advanced-search-form"
      onFinish={this._submitData}
      initialValues={detailData}
    >
      <Row gutter={24}>{this._getFields()}</Row>
      {submitErrorMsg?(
        <Row gutter={24}><Col span={24}>
            <Message error list={[submitErrorMsg]} style={{ marginBottom: '3px' }} />
          </Col></Row>
        ):("")
      }
    </Form>
    );
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };

  _createChangeLogGrid = () =>{
    const { className = 'grid-wrapper' } = this.props;
    const { userChangeLogList } = this.props.userManageControlModal;
    const sortedRows = _.orderBy(userChangeLogList, ['createTime'], ['desc']);
    return (
      <div className={`ag-theme-balham-dark ${className}`} style={{width:'100%',height:'100%',minHeight:"300px"}}>
        <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={sortedRows}
            suppressAggFuncInHeader={true}
            animateRows={true}
            enableRangeSelection={true}
            rowSelection="single"
            onGridReady={this.onGridReady}
          />
        </div>
    )
  }

  _createAccessLogGrid = () =>{
    const { className = 'grid-wrapper' } = this.props;
    const { userAccessLogList } = this.props.userManageControlModal;
    const sortedRows = _.orderBy(userAccessLogList, ['createTime'], ['desc']);
    return (
      <div className={`ag-theme-balham-dark ${className}`} style={{width:'100%',height:'100%',minHeight:"300px"}}>
        <AgGridReact
            // properties
            columnDefs={userAccessLogGridColumn}
            defaultColDef={this.state.defaultColDef}
            rowData={sortedRows}
            suppressAggFuncInHeader={true}
            animateRows={true}
            enableRangeSelection={true}
            rowSelection="single"
            onGridReady={this.onGridReady}
          />
        </div>
    )
  }

  render() {
    const {
      isOpened,
      mode,
      submitDataStatus,
      detailData
    } = this.props.userManageControlModal;

    const isNewMode = mode === OperationtMode.NEW;
    const isUpdateMode = mode === OperationtMode.UPDATE;
    const status = submitDataStatus;

    let modalHeaderContent = 'User Detail';
    if (isNewMode) {
      modalHeaderContent = 'Add User';
    } else if (isUpdateMode) {
      modalHeaderContent = 'Edit User';
    }
    if(detailData && detailData.englishName){
      modalHeaderContent = `${modalHeaderContent}(${detailData.englishName})`
    }

    return (
      <div>
        <style>
          {`
            .ui.modals {
              display: flex !important;
            }
            .ui.modal {
              margin-top: 0px !important;
            }

          `}
        </style>
        <Modal key="UserManageModal" size="large" open={isOpened}>
          <Header content={modalHeaderContent} />
          <Modal.Content>
            <Tabs tabPosition="top" key="RightTabs" defaultActiveKey="1" >
                <Tabs.TabPane tab="User Info" key="1">
                  {this._createForm()}
                </Tabs.TabPane>
                {OperationtMode.DETAIL === mode &&
                  <Tabs.TabPane tab="Change Log" key="2">
                    {this._createChangeLogGrid()}
                  </Tabs.TabPane>
                }
                {OperationtMode.DETAIL === mode &&
                  <Tabs.TabPane tab="Access Log" key="3">
                    {this._createAccessLogGrid()}
                  </Tabs.TabPane>
                }
            </Tabs>
          </Modal.Content>
          <Modal.Actions>
            {this._readOnly()?(""):
              ( {
                ERROR: (
                  <Button onClick={this.submitForm}>
                     Submit Fail - Retry?
                  </Button>
                ),
                READY: (
                  <Button onClick={this.submitForm}>
                      Submit
                  </Button>
                )
              }[status])
              }
              <Button onClick={this.props.closeUserManageControlModal}>
                Cancel
              </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default UserManageDialog;
